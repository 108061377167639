<template>
    <section>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <shop-meb />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import shopMeb from '../../components/element/shop-meb.vue'
export default {
    name: 'edit-shop-meb',
    components: {
        shopMeb
    }
}
</script>